import logo from "./logo.png";

function App() {
  return (
    <div
      style={{
        backgroundColor: "#000000",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        width: "100vw",
        height: "100vh",
      }}
    >
      <a href="http://sw.world.evul.nu" style={{ color: "#f0f0f0" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="logo" style={{ width: "90%", maxWidth: 800 }} />
          <p style={{ fontSize: 30 }}>ROLEPLAYING CHRONICLE</p>
        </div>
      </a>
      <a href="http://sw.gm.evul.nu" style={{ color: "#f0f0f0", padding: 30 }}>
        Game Master Page
      </a>
    </div>
  );
}

export default App;
